$(document).ready(function() {
	if(typeof window.getComputedStyle(document.body).backgroundBlendMode == 'undefined') {
		document.documentElement.className += " no-background-blend-mode";
	}
	
	var enablePopup = true;
	if (window.matchMedia) {
		var mq = window.matchMedia("(min-width: 700px)");
		enablePopup = mq.matches;
	}
	
	if (enablePopup) {
		$('.gallery').magnificPopup({
			type: 'image',
			delegate: 'a',
			mainClass: 'mfp-no-margins mfp-with-zoom',
			removalDelay: 300,
			preload: true,
		});
	}
	
	$('.ps-popup').magnificPopup({
		type: 'inline'
	});

	$('a[href^="#"]').on('click', function(event) {
		event.preventDefault();
		scrollTo($(this).attr('href'));
	});

	$('a.store').on('click', function(event) {
		if (window._gat && window._gat._getTracker) {
			var url = $(this).attr('href');
			event.preventDefault();
			ga('send', 'event', 'outbound', 'click', url, {'hitCallback':
				function () {
					document.location = url;
				}
			});
			window.setTimeout(function() {
				document.location = url;
			}, 1000);
		}
	});
});

function scrollTo(id, complete) {
	var target = $(id);
	if (target.length) {
		var extraSpace = Math.max(($(window).height() - target.height()) / 2, 0);
		$('html, body').animate({
			scrollTop: target.offset().top - extraSpace
		}, 500, 'linear', complete);
	}
}